import request, { applicationJson } from "@/utils/axios";

export function getAgencyList() {
  return request.get("/campaign/agency");
}

export function addAgency(params) {
  return applicationJson.post("/campaign/agency", params);
}

export function modifyAgency(params) {
  return applicationJson.put("/campaign/agency", params);
}

export function delAgency(id) {
  return request.delete("/campaign/agency", { agencyId: id });
}

export function judgeAgencyName(params) {
  return request.get("/campaign/agency/existence", params);
}

export function getAgencyAccount(id = "") {
  return request.get("/agency/account", { agencyId: id });
}
