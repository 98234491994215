<template>
  <div style="flex: 1; background-color: #fff" class="repoartWrap">
    <div>
      <main-title title="活动基本信息" style="margin-bottom: 30px" />
      <div class="base_info_item_contain" v-loading="infoLoading">
        <p class="base_info_item">
          <span class="base_info_item_label">活动品牌</span>
          <span>{{ basicInfo.brand }}</span>
        </p>
        <p class="base_info_item">
          <span class="base_info_item_label">活动说明</span>
          <span>{{ basicInfo.campaignDesc }}</span>
        </p>
        <p class="base_info_item">
          <span class="base_info_item_label">相关文档</span>
          <svg-icon
            class-name="download"
            @click="downloadDoc(basicInfo.docDir)"
            style="
              fill: skyblue;
              font-size: 24px;
              cursor: pointer;
              vertical-align: middle;
            "
            v-if="basicInfo.docDir"
          ></svg-icon>
          <span v-else>无</span>
        </p>
        <p class="base_info_item" style="width: 100%">
          <span class="base_info_item_label">活动名称</span>
          <span>{{ basicInfo.campaignName }}</span>
        </p>
        <p class="base_info_item">
          <span class="base_info_item_label">活动时间</span>
          <span>{{
            basicInfo.duration ? basicInfo.duration.split(",").join(" 至 ") : ""
          }}</span>
        </p>
        <p class="base_info_item">
          <span class="base_info_item_label">活动预算</span>
          <span>￥{{ sliceDecimal(basicInfo.cost / 100) }}</span>
        </p>
        <p class="base_info_item">
          <span class="base_info_item_label">活动类型</span>
          <span>{{ basicInfo.campaginType }}</span>
        </p>
      </div>
    </div>
    <el-tabs type="border-card">
      <el-tab-pane>
        <template #label>
          <svg-icon
            class-name="pinggu"
            class="tab_icon"
            :class="isTabChosen ? 'isChosenColor' : ''"
          ></svg-icon>
          评估详情
        </template>
        <div style="margin-bottom: 20px">
          <main-title
            title="活动表现"
            isSecondColor
            style="margin-bottom: 20px"
          >
            <template #right>
              <el-button type="primary" size="mini" @click="uploadFile"
                >上传销量和搜索指数</el-button
              >
              <el-button
                type="primary"
                size="mini"
                @click="downloadReport"
                style="float: right"
                :disabled="
                  JSON.stringify(storedNum) === '{}' || downloadReportBtnLoading
                "
              >
                <i
                  class="el-icon-loading"
                  v-show="downloadReportBtnLoading"
                ></i>
                {{
                  downloadReportBtnLoading ? "活动报告导出中" : "活动报告下载"
                }}
              </el-button>
            </template>
          </main-title>
          <div style="margin-bottom: 10px">
            <span style="display: inline-block; width: 80px">目标人群</span>
            <el-select v-model="crowd" size="mini">
              <el-option
                v-for="item in formatCrowdList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-wrap: wrap;
            "
            v-loading="showCardBoxLoading"
          >
            <showCard
              :info="{ key: '投放费用', num: `${cardData.spending}` }"
              icon="price"
            ></showCard>
            <showCard
              :info="{ key: '曝光总次数', num: `${cardData.exposure}` }"
              icon="puguang"
            ></showCard>
            <showCard
              :showTip="true"
              :info="{ key: '曝光总人数', num: cardData.uv }"
              icon="numOfKol"
            ></showCard>
            <showCard
              :showTip="true"
              :info="{ key: '触达TA总人数', num: cardData.taUv }"
              icon="user"
            ></showCard>
            <showCard
              :info="{ key: '互动总次数', num: cardData.engagement }"
              icon="engagement"
            ></showCard>
            <showCard
              :info="{ key: '互动率', num: cardData.engagementRate }"
              icon="hudongl"
            ></showCard>
            <showCard
              :showTip="true"
              :info="{ key: 'CPI', num: cardData.cpi }"
              icon="CPI"
            ></showCard>
            <showCard
              :showTip="true"
              :info="{ key: 'CPUV', num: cardData.cpuv }"
              icon="CPUV"
            ></showCard>
            <showCard
              :showTip="true"
              :info="{ key: 'CPE', num: cardData.cpe }"
              icon="CPE"
            ></showCard>
            <showCard
              :showTip="true"
              :info="{ key: 'CPTA', num: cardData.cpta }"
              icon="CPTA"
            ></showCard>
          </div>
        </div>
        <div style="margin-bottom: 20px">
          <main-title
            title="平台表现"
            isSecondColor
            style="margin-bottom: 20px"
            class="clearfix"
          >
          </main-title>
          <el-table :data="performanceTableData" style="width: 100%">
            <el-table-column
              v-for="item in colData"
              :key="item.prop"
              :label="item.label"
              :prop="item.prop"
              :align="item.align"
              :width="item.width"
              :sortable="item.sortable"
              :formatter="item.formatter"
            >
            </el-table-column>
          </el-table>
        </div>
        <div>
          <main-title
            title="推文详情"
            isSecondColor
            style="margin-bottom: 20px"
            class="clearfix"
          >
            <template #left>
              <el-button
                type="primary"
                size="mini"
                @click="addDataVisible = true"
                style="margin-left: 20px"
                >添加后链数据
              </el-button>
              <el-button
                type="primary"
                size="mini"
                @click="uploadFileVisible = true"
                >文件上传
              </el-button>
            </template>
          </main-title>
          <el-tabs v-model="activeTab" type="card" @tab-click="handleClick">
            <el-tab-pane label="已在库后链数据" name="first">
              <stored-data-component
                ref="child"
                :campaignId="id"
                :crowd="crowd"
                :sites="sites"
              />
            </el-tab-pane>
            <el-tab-pane label="追加中后链数据" name="second">
              <add-data-component
                :platformNum="addNum"
                :campaignId="id"
                ref="addChild"
              />
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      width="80%"
      title="添加后链数据"
      v-model="addDataVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-table
        :data="tableData"
        :header-cell-style="headerStyle"
        :cell-style="cellStyle"
        stripe
      >
        <el-table-column width="50" label="序号" align="center">
          <template #default="scope">
            <el-button
              type="text"
              icon="el-icon-plus"
              v-if="scope.$index + 1 === tableData.length"
              @click="addNewData"
              :disabled="!(scope.row.url && scope.row.platform)"
            ></el-button>
            <span v-else>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="* 链接" prop="url" align="center" width="320">
          <template #default="scope">
            <el-input
              size="mini"
              v-model="scope.row.url"
              @change="analysisUrl(scope.$index)"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="* 平台" prop="platform" align="center">
          <template #default="scope">
            <el-select size="mini" v-model="scope.row.platform">
              <el-option
                v-for="item in sites"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="供应商" prop="agencyId" align="center">
          <template #default="scope">
            <el-select size="mini" v-model="scope.row.agencyId" clearable>
              <el-option
                v-for="item in agencyOptions"
                :key="item.id"
                :value="item.id"
                :label="item.agencyName"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="费用" prop="cost" align="center">
          <template #default="scope">
            <el-input
              size="mini"
              v-model="scope.row.cost"
              @blur="judgePrice"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="是否报备" prop="report" align="center">
          <template #default="scope">
            <el-input size="mini" v-model="scope.row.report"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="推流费用" prop="flowCost" align="center">
          <template #default="scope">
            <el-input size="mini" v-model="scope.row.flowCost"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="ROI" prop="roi" align="center">
          <template #default="scope">
            <el-input size="mini" v-model="scope.row.roi"></el-input>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <span style="color: red; font-size: 12px" v-show="errorTip">
            * 请输入链接和平台
          </span>
          <div style="flex: 1">
            <el-button size="mini" @click="closeSubmitAddDataDialog"
              >取 消
            </el-button>
            <el-button size="mini" type="primary" @click="submitAddData"
              >确 定
            </el-button>
          </div>
        </div>
      </template>
    </el-dialog>
    <el-dialog
      width="40%"
      v-model="uploadFileVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div style="text-align: center">
        <el-upload
          ref="upload"
          :multiple="false"
          :headers="{ token }"
          :action="action"
          :data="{ campaignId: id }"
          :before-upload="beforeUpload"
          :on-success="success"
          :on-error="error"
          :file-list="fileList"
          drag
          accept=".xls,.xlsx"
          class="upload_contain"
        >
          <i class="el-icon-upload"></i>
          <p class="el-upload__text">将文件拖到此处，或<em>点击上传</em></p>
        </el-upload>
        <el-button
          style="margin-top: 10px"
          size="mini"
        >
          <a
            href="https://omnivoice-kol.oss-cn-hangzhou.aliyuncs.com/omnivoice/template-files/%E5%90%8E%E9%93%BE%E6%95%B0%E6%8D%AE%E6%96%87%E4%BB%B6%E6%A8%A1%E6%9D%BF.xlsx"
            >后链数据模板下载</a
          >
        </el-button>
      </div>
      <template #footer>
        <div>
          <el-button size="mini" @click="closeUploadFileDialog"
            >取 消
          </el-button>
          <el-button
            size="mini"
            type="primary"
            :disabled="!uploadFileDir"
            @click="submitUploadFile"
            >确 定
          </el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog
      title="上传销量和搜索指数"
      v-model="uploadSearchIndexFlag"
      width="650px"
    >
      <el-upload
        :before-upload="fileBeforeUpload"
        :on-success="fileOnSuccess"
        :on-error="fileOnError"
        :on-remove="fileRemove"
        class="upload_kolTag"
        drag
        :data="{ campaignId: id }"
        :headers="{ token }"
        accept=".xls,.xlsx"
        :limit="1"
        ref="upload"
        :action="uploadAction"
        multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <template #tip>
          <div class="el-upload__tip" style="color: red">
            *只能上传excel文件，且不超过100M
          </div>
          <a
            class="downloadBtn"
            href="https://omnivoice-kol.oss-cn-hangzhou.aliyuncs.com/omnivoice/template-files/%E9%94%80%E9%87%8F_%E6%90%9C%E7%B4%A2%E6%8C%87%E6%95%B0%E6%96%87%E4%BB%B6%E6%A8%A1%E7%89%88.xlsx"
          >
            点击下载上传销量和搜索指数模版
          </a>
        </template>
      </el-upload>
      <template #footer>
        <el-button @click="cancelUploadFile">取 消</el-button>
        <el-button :disabled="btnDisable" type="primary" @click="addFileSubmit"
          >确 定
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import mainTitle from "@/components/common/mainTitle";
import showCard from "./showCard";
import { mapGetters } from "vuex";
import storedDataComponent from "./storedDataComponent";
import addDataComponent from "./addDataComponent";
// import staggerXBar from "@/components/echarts/staggerXBar";
import {
  getOverview,
  addNostoredData,
  submitUploadFile,
  downloadReport,
  getCampaignEvaluationPersona,
  getCampaignPlatformPerformance,
  submissionUploadSalesFile,
} from "@/api/campaignReport";
import { getAgencyList } from "@/api/agency";
import { getCampaignInfo } from "@/api/campaignInfo";

export default {
  name: "index",
  data() {
    return {
      // 表格样式
      headerStyle: {
        backgroundColor: "#F9FCFE",
        borderTopWidth: "1px",
        borderTopColor: "#eee",
        borderTopStyle: "solid",
      },
      cellStyle: {
        fontSize: "12px",
      },
      crowd: "32_1",
      platform: "all",
      cardData: {
        spending: 0,
        impression: 0,
        engagement: 0,
        engagementRate: "0%",
        cpe: 0,
        cpi: 0,
        cpta: 0,
        uv: 0,
        taUv: 0,
        cpuv: 0,
      },
      activeTab: "first",
      storedNum: {},
      addNum: {},
      addDataVisible: false,
      uploadFileVisible: false,
      tableData: [
        {
          url: "",
          agencyId: "",
          platform: "",
          cost: undefined,
          roi: null,
          flowCost: null,
          report: null,
        },
      ],
      agencyOptions: [{ id: "", agencyName: "" }],
      action: process.env.VUE_APP_API_HOST + "/campaign/evaluation/links/file",
      uploadFileDir: "",
      showCardBoxLoading: true,
      errorTip: false,
      fileList: [],
      infoLoading: false,
      basicInfo: {
        brand: "",
        campaignDesc: "",
        campaignName: "",
        duration: "",
        cost: null,
        docDir: null,
        report: null,
      },
      isTabChosen: true,
      downloadReportBtnLoading: false,
      profileData: [],
      performanceTableData: [],
      colData: [
        {
          label: "平台",
          prop: "platform",
          sortable: false,
          width: null,
          align: "center",
        },
        {
          label: "投放费用",
          prop: "spending",
          sortable: false,
          width: null,
          align: "center",
          formatter: (row, column, cellValue) => {
            return cellValue !== "--"
              ? "￥" + this.sliceDecimal(cellValue)
              : "--";
          },
        },
        {
          label: "曝光总次数",
          prop: "exposure",
          sortable: false,
          width: null,
          align: "center",
          formatter: (row, column, cellValue) => {
            return cellValue !== "--" ? this.sliceDecimal(cellValue) : "--";
          },
        },
        {
          label: "曝光总人数",
          prop: "uv",
          sortable: false,
          width: null,
          align: "center",
          formatter: (row, column, cellValue) => {
            return cellValue !== "--" ? this.sliceDecimal(cellValue) : "--";
          },
        },
        {
          label: "触达TA总人数",
          prop: "taUv",
          sortable: false,
          width: null,
          align: "center",
          formatter: (row, column, cellValue) => {
            return cellValue !== "--" ? this.sliceDecimal(cellValue) : "--";
          },
        },
        {
          label: "互动总次数",
          prop: "engagement",
          sortable: false,
          width: null,
          align: "center",
          formatter: (row, column, cellValue) => {
            return cellValue !== "--" ? this.sliceDecimal(cellValue) : "--";
          },
        },
        {
          label: "互动率",
          prop: "engagementRate",
          sortable: false,
          width: null,
          align: "center",
          // formatter: (row, column, cellValue) => {
          //   return cellValue !== "--" ? this.sliceDecimal(cellValue) : "--";
          // },
        },
        {
          label: "CPE",
          prop: "cpe",
          sortable: false,
          width: null,
          align: "center",
          formatter: (row, column, cellValue) => {
            return cellValue !== "--"
              ? "￥" + this.sliceDecimal(cellValue)
              : "--";
          },
        },
        {
          label: "CPI",
          prop: "cpi",
          sortable: false,
          width: null,
          align: "center",
          formatter: (row, column, cellValue) => {
            return cellValue !== "--"
              ? "￥" + this.sliceDecimal(cellValue)
              : "--";
          },
        },
        {
          label: "CPUV",
          prop: "cpuv",
          sortable: false,
          width: null,
          align: "center",
          formatter: (row, column, cellValue) => {
            return cellValue !== "--"
              ? "￥" + this.sliceDecimal(cellValue)
              : "--";
          },
        },
        {
          label: "CPTA",
          prop: "cpta",
          sortable: false,
          width: null,
          align: "center",
          formatter: (row, column, cellValue) => {
            return cellValue !== "--"
              ? "￥" + this.sliceDecimal(cellValue)
              : "--";
          },
        },
      ],
      uploadSearchIndexFlag: false,
      uploadAction:
        process.env.VUE_APP_API_HOST + "/campaign/evaluation/sales/file",
      filePath: "",
      btnDisable: true,
    };
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters(["crowdList", "token", "sites"]),
    params() {
      return {
        campaignId: this.id,
        ta: this.crowd,
        platform: this.platform,
      };
    },
    formatCrowdList() {
      return this.crowdList.map((v) => {
        return {
          value: v.key,
          label: v.name,
        };
      });
    },
  },
  mounted() {
    this.initBaseInfo();
    this.getOverview();
    this.getAgencyOptions();
    this.getEvaluationPersona();
    this.getPerformance();
    this.$refs.child.initTableList();
    this.$refs.addChild.initTableList();
  },

  methods: {
    async addFileSubmit() {
      let { code } = await submissionUploadSalesFile({
        fileName: this.filePath.replace(/\\/g, "/").split("/").pop(),
        campaignId: this.id,
      });
      if (code === 0) {
        this.$message({
          type: "success",
          message: "上传成功!",
        });
        this.uploadSearchIndexFlag = false;
      } else {
        this.$message.error("上传失败");
      }
    },
    fileRemove() {
      this.btnDisable = true;
    },
    fileOnError() {
      this.$refs.upload.clearFiles();
      this.$message.error("上传文件失败!");
    },
    fileOnSuccess(res) {
      // 上传文件成功，取消禁用按钮
      if (res.code === 9004) {
        this.$message({
          type: "error",
          message: "文件解析失败,请查看模版文件!",
        });
        this.$refs.upload.clearFiles();
      } else if (res.code === 123) {
        this.$message.error("上传失败，请联系管理员!");
      } else if (res.code === 0) {
        this.filePath = res.data;
        this.btnDisable = false;
      }
    },
    fileBeforeUpload(file) {
      const sizeLimit = 1024 * 1024 * 30;
      if (file.size > sizeLimit) {
        this.$message({
          message: "文件不能大于30M",
          type: "warning",
        });
        return false;
      } else if (file.size < 0) {
        this.$message({
          message: "文件不能为空!",
        });
      } else {
        return true;
      }
    },
    uploadFile() {
      this.uploadSearchIndexFlag = true;
    },
    cancelUploadFile() {
      this.uploadSearchIndexFlag = false;
    },
    async getEvaluationPersona() {
      let { code, data } = await getCampaignEvaluationPersona({
        campaignId: this.id,
      });
      const yAxisData = [
        "wechat",
        "weibo",
        "xiaohongshu",
        "douyin",
        "bilibili",
        "kuaishou",
      ];
      this.profileData = data.map((item) => {
        const arr = [];
        const temp = {
          yAxisData,
        };
        switch (item.name) {
          case "age":
            temp.title = "年龄占比";
            break;
          case "gender":
            temp.title = "性别占比";
            break;
          case "income":
            temp.title = "收入水平占比";
            break;
          case "city_tier":
            temp.title = "城市级别占比";
            break;
        }
        temp.xAxisData = item.profile.map((temp) => temp.label);
        const keyArr = item.profile.map((temp) => temp.id);
        yAxisData.forEach((platform) => {
          const temp = [];
          keyArr.forEach((key) => {
            if (item.proportionObj !== null) {
              temp.push(
                item.proportionObj[platform] &&
                  item.proportionObj[platform][key]
                  ? Math.floor(item.proportionObj[platform][key] * 100)
                  : null
              );
            } else {
              temp.push(null);
            }
          });
          const index = temp.findIndex((item) => item != null);
          if (index !== -1) {
            // 更改temp的最后一个值以保证temp每项相加和为100
            let endValue = 100;
            temp.slice(0, -1).forEach((item) => {
              endValue = endValue - item;
            });
            temp[temp.length - 1] = endValue <= 0 ? 0 : endValue;
          }
          arr.push(temp);
        });
        // 转置
        temp.seriesData = arr[0].map((col, i) => arr.map((row) => row[i]));
        return temp;
      });
    },
    async getPerformance() {
      let res = await getCampaignPlatformPerformance({
        campaignId: this.id,
        ta: this.crowd,
      });
      if (res.code === 0) {
        this.formatNull(res.data);
        this.performanceTableData = res.data;
      } else {
        this.$message({
          message: "请求失败，请联系管理员",
          type: "error",
        });
      }
    },
    async initBaseInfo() {
      this.infoLoading = true;
      const params = { campaignId: this.id };
      const { data } = await getCampaignInfo(params);
      this.basicInfo = data;
      this.infoLoading = false;
    },
    async getAgencyOptions() {
      const { data } = await getAgencyList();
      this.agencyOptions = data;
    },
    async getOverview() {
      this.showCardBoxLoading = true;
      const {
        data: { performance, number },
      } = await getOverview(this.params);
      for (let k in performance) {
        if (performance[k] === null) {
          performance[k] = 0;
        }
      }
      this.cardData = performance;
      this.storedNum = number.stored;
      this.addNum = number.notStored;
      this.showCardBoxLoading = false;
    },
    addNewData() {
      this.tableData.push({
        url: "",
        agencyId: "",
        platform: "",
        cost: undefined,
        roi: null,
        flowCost: null,
        report: null,
      });
    },
    agencyName(id) {
      const agency = id
        ? this.agencyOptions.find((v) => v.id === id)
        : { agencyName: "" };
      return agency.agencyName;
    },
    closeSubmitAddDataDialog() {
      this.addDataVisible = false;
      this.tableData = [
        {
          url: "",
          agencyId: "",
          platform: "",
          cost: undefined,
          roi: null,
          flowCost: null,
          report: null,
        },
      ];
    },
    async submitAddData() {
      if (this.validateTable()) {
        this.errorTip = true;
        return;
      }
      const links = this.tableData.map(
        ({ agencyId, cost, url, platform, roi, flowCost, report }) => {
          cost = this.formatStringNum(cost);
          return {
            agencyName: this.agencyName(agencyId),
            agencyId,
            cost: cost && cost > 0 ? cost * 100 : null,
            url,
            platform,
            roi,
            flowCost,
            report,
          };
        }
      );
      const params = { campaignId: this.id, links };
      let res = await addNostoredData(params);
      if (res.code === 3012) {
        this.$message.error("提交失败,超出后测额度，请联系管理员");
      } else {
        await this.getOverview();
        this.activeTab = "first";
        await this.$refs.child.initTableList();
        await this.$refs.addChild.initTableList();
        this.addDataVisible = false;

        this.tableData = [
          {
            url: "",
            agencyId: "",
            platform: "",
            cost: undefined,
            roi: null,
            flowCost: null,
            report: null,
          },
        ];
      }
    },
    handleClick(v) {
      v.$children[0].initTableList();
    },
    beforeUpload(file) {
      // 对上传的文件进行限制
      const testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "xlsx" || testmsg === "xls";
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!extension) {
        this.$message({
          message: "上传文件只能是xlsx或者xls格式!",
          type: "warning",
        });
      }
      if (!isLt10M) {
        this.$message({
          message: "上传文件大小不能超过 10MB!",
          type: "warning",
        });
      }
      return extension && isLt10M;
    },
    success(res, file, fileList) {
      // 上传成功
      if (!res.code) {
        this.$message({
          message: "上传成功！",
          type: "success",
        });
        const i = res.data.lastIndexOf("/");
        const fileName = res.data.slice(i + 1);
        this.uploadFileDir = fileName;
      } else {
        this.$refs.upload.onError(res.code, file, fileList);
      }
    },
    error(err) {
      let message;
      switch (err) {
        case 204:
          message =
            "文件内容格式错误，请检查表头格式，平台字段和费用字段的格式是否符合标准格式";
          break;
        case 3012:
          message = "超出后测额度，请联系管理员";
          break;
        default:
          message = "上传文件失败!";
      }
      // 上传失败
      this.$message({
        message: message,
        type: "error",
      });
      this.fileList = [];
    },
    closeUploadFileDialog() {
      this.uploadFileDir = "";
      this.uploadFileVisible = false;
      this.fileList = [];
    },
    async submitUploadFile() {
      let params = {
        campaignId: this.id,
        fileName: this.uploadFileDir,
      };
      await submitUploadFile(params);
      this.closeUploadFileDialog();
      await this.getOverview();
      this.activeTab = "first";
      await this.$refs.child.initTableList();
    },
    async downloadReport() {
      this.downloadReportBtnLoading = true;
      this.$message({
        message: "活动报告正在导出，请耐心等待，不要刷新切换页面。",
      });
      const params = {
        campaignId: this.id,
        ta: this.crowd,
      };
      await downloadReport(params, this.basicInfo.campaignName);
      this.downloadReportBtnLoading = false;
    },
    analysisUrl(i) {
      const url = this.tableData[i].url;
      const platform = this.urlToPlatform(url);
      this.tableData[i].platform = platform;
    },
    validateTable() {
      return this.tableData.find((item) => !item.url || !item.platform);
    },
    judgePrice(e) {
      e.target.value = this.formatStringNum(e.target.value);
    },
    formatStringNum(num) {
      let res = null;
      if (num) {
        const numStr = num.toString();
        const index = numStr.indexOf(".");
        res = index === -1 ? numStr : numStr.slice(0, index);
        res = res.replace(/\D/g, "") * 1;
        if (res < 1) {
          res = null;
        }
      }
      return res;
    },
  },
  watch: {
    params() {
      this.getOverview();
      this.getPerformance();
    },
    errorTip(newVal) {
      newVal
        ? setTimeout(() => {
            this.errorTip = false;
          }, 3000)
        : "";
    },
  },
  components: {
    mainTitle,
    showCard,
    storedDataComponent,
    addDataComponent,
    // staggerXBar,
  },
};
</script>

<style lang="scss">
.repoartWrap {
  .upload_kolTag {
    text-align: center;
    position: relative;

    .el-upload-list.el-upload-list--text {
      position: absolute;
      top: 54%;
      left: 45%;
      transform: translateX(-50%);

      .el-upload-list__item-name {
        max-width: 240px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
      }
    }

    .downloadBtn {
      margin-top: 10px;
      cursor: pointer;
      border: 1px solid #ccc;
      border-radius: 5px;
      display: inline-block;
      height: 30px;
      line-height: 30px;
      padding: 0 10px;

      &:hover {
        color: #409eff;
        border-color: #c6e2ff;
        background-color: #ecf5ff;
      }
    }
  }
}

.repoartWrap {
  .staggerXBar {
    margin-bottom: 35px !important;
  }
}
.tab_icon {
  margin-right: 5px;

  &:hover {
    fill: #409eff;
  }
}

.isChosenColor {
  fill: #409eff;
}

.base_info_item_contain {
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;

  .base_info_item {
    height: 50px;
    width: 30%;
    margin-right: 3%;

    .base_info_item_label {
      margin-right: 30px;
      font-size: 16px;
      font-weight: 700;
      color: #606266;
    }
  }
}

.upload_contain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .el-upload-list,
  .el-upload-list--text {
    margin-top: 10px;
    margin-left: -55px;
  }
}
</style>
