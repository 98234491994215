import request, {
  downloadFile,
  downloadLocalFile,
  applicationJson,
  uploadFile,
} from "@/utils/axios";

export function getOverview(params) {
  return request.get("/campaign/evaluation/overview", params);
}

export function getStoredList(params) {
  return request.get("/campaign/evaluation/links", params);
}

export function getAddList(params) {
  return request.get("/campaign/evaluation/newLinks", params);
}

export function addNostoredData(params) {
  return applicationJson.post("/campaign/evaluation/links", params);
}

export function submitUploadFile(params) {
  return request.post("/campaign/evaluation/links/file/submission", params);
}

// campaign/report
export function downloadReport(params, fileName) {
  return downloadFile("/campaign/report", params, fileName + ".xlsx");
}

export function downloadAddDataStatus(parmas) {
  return downloadFile(
    "/campaign/evaluation/newLinks/file",
    parmas,
    "追加状态.xlsx"
  );
}

export function downloadTemplate() {
  return downloadLocalFile("./后链数据文件模板.xlsx", "后链数据文件模板.xlsx");
}

//

// 活动评估人群画像 /campaign/evaluation/persona
export function getCampaignEvaluationPersona(params) {
  return request.get("/campaign/evaluation/persona", params);
}

// 个平台表现 /campaign/evaluation/platform/performance
export function getCampaignPlatformPerformance(params) {
  return request.get("/campaign/evaluation/platform/performance", params);
}

// 上传销量指数 /campaign/evaluation/sales/file
//
export function uploadSalesFile(params) {
  return uploadFile("/campaign/evaluation/sales/file", params);
}

// 确定上传销量指数文件 /campaign/evaluation/sales/file/submission
export function submissionUploadSalesFile(params) {
  return request.post("/campaign/evaluation/sales/file/submission", params);
}
