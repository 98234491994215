<template>
  <div class="box">
    <div class="imgBox">
      <svg-icon :class-name="icon" class="priceClass" />
    </div>
    <div class="info">
      <p class="num">
        <count-to
          :start-val="0"
          :prefix="isPrice ? '¥' : ''"
          :suffix="isPercentage ? '%' : ''"
          :decimals="isDecimals ? 2 : 0"
          :end-val="formatNum(info) === 'N/A' ? 0 : formatNum(info)"
          :duration="1000"
        />
      </p>
      <slot></slot>
      <p class="key">
        <!-- <el-tooltip
          v-if="showTip"
          class="item"
          effect="dark"
          :content="tips(info.key)"
          placement="top"
        > -->
        <!-- <svg-icon class-name="helpIcon" class="questionStyle"></svg-icon> -->
        <!-- </el-tooltip> -->
        {{ info.key }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isPrice: false,
      isPercentage: false,
      isDecimals: false,
      idFormat: `asd`,
    };
  },
  props: {
    icon: {
      type: String,
      default: "price",
    },
    bgc: {
      type: String,
      default: "red",
    },
    size: {
      type: Object,
      default() {
        return {
          width: "140px",
          height: "30px",
        };
      },
    },
    info: {
      type: Object,
      default() {
        return {
          key: "总价",
          num: 20000,
        };
      },
    },
    isLittle: {
      type: Boolean,
      default: false,
    },
    showTip: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatNum(info) {
      switch (info.key) {
        case "投放费用":
          this.isPrice = true;
          if (info.num === "null") {
            return 0;
          }
          return info.num - 0;
        case "互动率":
          if (info.num === 0) {
            return info.num - 0;
          }
          this.isDecimals = true;
          this.isPercentage = true;
          return info.num !== null ? +info.num.replace(/%/g, "") : 0;
        case "曝光总次数":
        case "互动总次数":
        case "触达TA总人数":
        case "曝光总人数":
          return info.num - 0;
        default:
          this.isDecimals = true;
          this.isPrice = true;
          return info.num - 0;
      }
    },
    tips(key) {
      switch (key) {
        case "互动率":
          return "互动量 / 曝光数";
        case "CPE":
          return "单个互动成本";
        case "CPI":
          return "单个UV触达成本";
        case "CPTA":
          return "单个TA触达成本";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  background: #409eff;
  width: 260px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 1px 5px 4px #ccc;
  margin-bottom: 10px;

  .imgBox {
    .priceClass {
      font-size: 40px;
      fill: #fff;
      opacity: 0.9;
    }
  }

  .info {
    text-align: right;

    .num {
      color: #fff;
      font-size: 16px;
      font-weight: 800;
    }

    .key {
      color: #fff;
    }

    .questionStyle {
      cursor: pointer;
      fill: #fff;
    }
  }
}
</style>
